
import { Component, Vue } from "vue-property-decorator";

@Component({
    name: "InventoryLocationsPage",
    components: {
    }
})
export default class InventoryLocationsPage extends Vue {

    private locations = [];

    private selectedId = 0;
    private selectedName = "";

    mounted() {
        this.load();
    }

    async load() {
        try {
            const response = await this.$http.get("/inventory/inventory-locations");
            this.locations = response.data;
        } catch (error) {
            console.warn(error);
        }
    }

    get fields() {
        return [
            "name",
            // "storeName",
            { key: "actions", class: "col-actions", label: "" }
        ];
    }

    startAdd() {
        this.selectedId = 0;
        this.selectedName = "";
        this.$bvModal.show("inventory-location-modal");
    }

    startEdit(item: any) {
        this.selectedId = item.id;
        this.selectedName = item.name;
        this.$bvModal.show("inventory-location-modal");
    }

    async save(e: any) {
        e.preventDefault();

        try {
            const location = {
                id: this.selectedId,
                name: this.selectedName,
            }
            await this.$http.post("/inventory/inventory-location", location);
            this.load();
            this.$bvModal.hide("inventory-location-modal");
        } catch {
            //show error
        }
    }

}

